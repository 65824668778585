// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PhoneLink from "components/Links/PhoneLink";
import EmailLink from "components/Links/EmailLink";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import "./privacy.module.css";

function ChkpAppPrivacyPolicy() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox maxWidth={1000} margin={"auto"} mt={5}>
        <MDBox>
          <MDTypography variant="h2" mb={2}>
            Polityka Prywatności aplikacji mobilnej "ChKP - rozliczenia"
          </MDTypography>
          <p>
            Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników aplikacji mobilnej "ChKP - rozliczenia" (dalej: "Aplikacja"). Dokładamy wszelkich starań, aby zapewnić najwyższy poziom ochrony prywatności użytkowników oraz zgodność z obowiązującymi przepisami prawa, w tym Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 (dalej: "RODO").
          </p>
          <MDTypography variant="h4" mt={2}>
            1. Administrator Danych
          </MDTypography>
          <p>
            Administratorem danych osobowych jest [Nazwa firmy lub osoba odpowiedzialna], z siedzibą pod adresem [adres firmy], e-mail: [adres e-mail kontaktowy] (dalej: "Administrator").
          </p>
          <MDTypography variant="h4" mt={2}>
            2. Zakres przetwarzanych danych
          </MDTypography>
          <p>
            W ramach korzystania z Aplikacji przetwarzamy następujące dane osobowe użytkowników:
          </p>
          <ul>
            <li>Imię i nazwisko</li>
            <li>Adres e-mail</li>
            <li>Dane dotyczące rozliczeń i transakcji</li>
            <li>Informacje techniczne dotyczące korzystania z Aplikacji (np. adres IP, identyfikatory urządzenia, logi systemowe)</li>
            <li>Inne dane podane dobrowolnie przez użytkownika w ramach korzystania z Aplikacji</li>
          </ul>
          <p>
            Podanie danych jest dobrowolne, lecz niezbędne do korzystania z niektórych funkcjonalności Aplikacji.
          </p>
          <MDTypography variant="h4" mt={2}>
            3. Cele i podstawy prawne przetwarzania danych
          </MDTypography>
          <p>
            Dane osobowe przetwarzamy w następujących celach:
          </p>
          <ul>
            <li>Realizacja funkcjonalności Aplikacji – art. 6 ust. 1 lit. b RODO (wykonanie umowy lub podjęcie działań przed jej zawarciem)</li>
            <li>Obsługa zapytań i wsparcie użytkownika – art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora)</li>
            <li>Analiza i poprawa działania Aplikacji – art. 6 ust. 1 lit. f RODO</li>
            <li>Spełnianie obowiązków prawnych, np. podatkowych czy rachunkowych – art. 6 ust. 1 lit. c RODO</li>
          </ul>
          <MDTypography variant="h4" mt={2}>
            4. Udostępnianie danych osobowych
          </MDTypography>
          <p>
            Dane mogą być przekazywane podmiotom współpracującym z Administratorem, takim jak:
          </p>
          <ul>
            <li>Dostawcy usług IT i hostingowych</li>
            <li>Podmioty księgowe i doradcze</li>
            <li>Organy administracji publicznej, jeśli wymagają tego przepisy prawa</li>
          </ul>
          <MDTypography variant="h4" mt={2}>
            5. Okres przechowywania danych
          </MDTypography>
          <p>
            Dane osobowe będą przechowywane przez okres niezbędny do realizacji celów przetwarzania, tj.:
          </p>
          <ul>
            <li>Do czasu realizacji usługi lub korzystania z Aplikacji</li>
            <li>Do momentu przedawnienia ewentualnych roszczeń</li>
            <li>W przypadku zgody marketingowej – do jej wycofania</li>
            <li>W przypadku zobowiązań prawnych – zgodnie z wymogami przepisów prawa</li>
          </ul>
          <MDTypography variant="h4" mt={2}>
            6. Prawa użytkownika
          </MDTypography>
          <ul>
            <li>Prawo dostępu do swoich danych</li>
            <li>Prawo do sprostowania danych</li>
            <li>Prawo do usunięcia danych ("prawo do bycia zapomnianym")</li>
            <li>Prawo do ograniczenia przetwarzania</li>
            <li>Prawo do przenoszenia danych</li>
            <li>Prawo do wniesienia sprzeciwu wobec przetwarzania</li>
            <li>Prawo do wycofania zgody na przetwarzanie danych osobowych w dowolnym momencie</li>
          </ul>
          <MDTypography variant="h4" mt={2}>
            7. Zmiany w Polityce Prywatności
          </MDTypography>
          <p>
            Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności, aby zapewnić jej zgodność z obowiązującymi przepisami prawa oraz dostosować do funkcjonalności Aplikacji.
          </p>
          
        </MDBox>
        <MDTypography variant="h4" mt={2}>
          Masz pytania lub chcesz, abyśmy usunęli Twoje dane? Napisz do nas
        </MDTypography>
        <EmailLink label="tomczyk006@gmail.com" link="mailto:tomczyk006@gmail.com" color="maintext" mt={1} />
      </MDBox>
    </DashboardLayout>
  );
}

export default ChkpAppPrivacyPolicy;
