import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import PocketBase from 'pocketbase';
import { Typography } from "@mui/material";
const new_posts = [
  {
    "title": "Spektakl „Doktor Dolittle i zwierzęta\"",
    "description": "Wczoraj dzieci z naszego przedszkola miały okazję uczestniczyć w niezwykłej przygodzie! 🌟 Wybraliśmy się na spektakl „Doktor Dolittle i zwierzęta\", gdzie razem z bohaterem poznawaliśmy fascynujący świat zwierząt.\nPrzedstawienie pełne było kolorowych postaci, humoru i emocjonujących przygód, które zachwyciły małych widzów. 🎬",
    "full_picture": "https://scontent.fpoz5-1.fna.fbcdn.net/v/t39.30808-6/481074470_1134134321845510_8343534237014151107_n.jpg?stp=dst-jpg_s960x960_tt6&_nc_cat=104&ccb=1-7&_nc_sid=833d8c&_nc_ohc=ncEP2PgD99kQ7kNvgFsnoJT&_nc_oc=Adj0knClXE4bfdtXHE4XPNAPxaM233fILoxwn2UrfWrhj7B-FEubyfHDv1U4x6-wSiw&_nc_zt=23&_nc_ht=scontent.fpoz5-1.fna&edm=AKIiGfEEAAAA&_nc_gid=AppVHVJ-ayIQc8LOvifl6Oe&oh=00_AYCZ2GhuhR87_KSyYITR_vMrY40RR9OPerwP0iMU3wAJiQ&oe=67C7AABE",
    "picture": "https://scontent.fpoz5-1.fna.fbcdn.net/v/t39.30808-6/481074470_1134134321845510_8343534237014151107_n.jpg?stp=dst-jpg_s173x172_tt6&_nc_cat=104&ccb=1-7&_nc_sid=833d8c&_nc_ohc=ncEP2PgD99kQ7kNvgFsnoJT&_nc_oc=Adj0knClXE4bfdtXHE4XPNAPxaM233fILoxwn2UrfWrhj7B-FEubyfHDv1U4x6-wSiw&_nc_zt=23&_nc_ht=scontent.fpoz5-1.fna&edm=AKIiGfEEAAAA&_nc_gid=AppVHVJ-ayIQc8LOvifl6Oe&oh=00_AYBzdmdZvmqN1R08Wu0jd4xICTaxkiq4rstPle_kazFFmg&oe=67C7AABE",
    "created_time": "2025-02-25T09:00:41+0000",
    "permalink_url": "https://www.facebook.com/832295225362756/posts/1134134208512188",
    "preschool": "przezmierowo",
    "id": "104528024496612_1134134208512188"
  },
]

function Basic() {
  const [form, setForm] = useState({
    title: '',
    description: '',
    date: new Date().toISOString().slice(0,10),
    photo: '',
    linkToFBPost: '',
    preschool: 'lusowo',
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pb = new PocketBase('https://atom.pockethost.io/');
  pb.autoCancellation(false);
  const navigate = useNavigate();

  // Function to convert Facebook date format to the required format
  const convertDateFormat = (fbDate) => {
    const date = new Date(fbDate);
    return date.toISOString().replace('T', ' ').substring(0, 19);
  };

  const pushPost = async () => {
    setIsLoading(true);
    setErrorMessage('');
    
    try {
      const formData = new FormData();
      formData.append('title', form.title);
      formData.append('description', form.description);
      formData.append('date', form.date);
      formData.append('linkToFBPost', form.linkToFBPost);
      
      // Handle file upload if a file is selected
      const fileInput = document.getElementById('fileInput');
      if (fileInput && fileInput.files.length > 0) {
        formData.append('photo', fileInput.files[0]);
      }
      
      formData.append('preschool', form.preschool);
      
      await pb.collection('posts').create(formData);
      setIsSuccess(true);
      
      // Reset form after successful submission
      setForm({
        title: '',
        description: '',
        date: new Date().toISOString().slice(0,10),
        photo: '',
        linkToFBPost: '',
        preschool: 'lusowo',
      });
      
      // Reset file input
      if (fileInput) {
        fileInput.value = '';
      }
    } catch (e) {
      console.error(e);
      if (e.data && e.data.data) {
        if (Object.keys(e.data.data).includes('title')) {
          setErrorMessage('Uzupełnij tytuł');
        }
        else if (Object.keys(e.data.data).includes('description')) {
          setErrorMessage('Uzupełnij opis');
        }
        else if (Object.keys(e.data.data).includes('photo')) {
          setErrorMessage('Wystąpił problem z wgraniem zdjęcia');
        }
        else {
          setErrorMessage('Wystąpił problem z wstawieniem postu');
        }
      } else {
        setErrorMessage('Wystąpił nieznany błąd');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const pushBulkPosts = async () => {
    setIsLoading(true);
    setErrorMessage('');
    let successCount = 0;
    let errorCount = 0;
    
    for (const post of new_posts) {
      try {
        const formData = new FormData();
        formData.append('title', post.title);
        formData.append('description', post.description || '');
        
        // Convert Facebook date format to the required format
        const formattedDate = convertDateFormat(post.created_time);
        formData.append('date', formattedDate);
        
        formData.append('linkToFBPost', post.permalink_url || '');
        formData.append('preschool', post.preschool || 'tarnowo');
        
        // Fetch and append the image
        if (post.full_picture) {
          try {
            const res = await fetch(post.full_picture);
            if (res.ok) {
              const imageBlob = await res.blob();
              formData.append('photo', imageBlob, 'image.jpg');
            }
          } catch (imgError) {
            console.error('Error fetching image:', imgError);
          }
        }
        
        await pb.collection('posts').create(formData);
        successCount++;
      } catch (e) {
        console.error('Error adding post:', post.title, e);
        errorCount++;
      }
    }
    
    setIsLoading(false);
    setIsSuccess(true);
    setErrorMessage(`Dodano ${successCount} postów. Błędów: ${errorCount}`);
  };

  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate("/logowanie");
    }
  }, [navigate]);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Wstawianie postu
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput 
                value={form.title} 
                onChange={e => setForm({...form, title: e.target.value})} 
                type="text" 
                label="Tytuł" 
                fullWidth 
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                value={form.description} 
                onChange={e => setForm({...form, description: e.target.value})} 
                type="text" 
                label="Opis" 
                multiline
                rows={4}
                fullWidth 
              />
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput 
                value={form.date} 
                onChange={e => setForm({...form, date: e.target.value})} 
                type="date" 
                fullWidth 
              />
              <InputLabel sx={{ marginLeft: '.8rem', fontSize: '.75rem', position: 'absolute', top: -9, padding: '3px', background: '#fff' }}>Data</InputLabel>
            </MDBox>
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel id="preschoolLabel">Przedszkole</InputLabel>
                <Select
                  sx={{ minHeight: '2.5rem' }}
                  labelId="preschoolLabel"
                  id="preschoolInput"
                  value={form.preschool}
                  label="przedszkole"
                  onChange={e => setForm({...form, preschool: e.target.value})} 
                >
                  <MenuItem value={'lusowo'}>Lusowo</MenuItem>
                  <MenuItem value={'tarnowo'}>Tarnowo Podgórne</MenuItem>
                  <MenuItem value={'przezmierowo'}>Przeźmierowo</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput 
                id="fileInput" 
                type="file" 
                fullWidth 
              />
              <InputLabel sx={{ marginLeft: '.8rem', fontSize: '.75rem', position: 'absolute', top: -9, padding: '3px', background: '#fff' }}>Zdjęcie</InputLabel>
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                value={form.linkToFBPost} 
                onChange={e => setForm({...form, linkToFBPost: e.target.value})} 
                type="text" 
                label="Link do postu na facebooku" 
                fullWidth 
              />
            </MDBox>
            {errorMessage &&
              <MDBox mb={2}>
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              </MDBox>
            }
            {isSuccess &&
              <MDBox mb={2}>
                <Typography variant="body2" color="success" align="center">
                  Poprawnie dodano post
                </Typography>
              </MDBox>
            }
            <MDBox mt={4} mb={1}>
              <MDButton 
                variant="gradient" 
                color="info" 
                fullWidth 
                onClick={pushPost}
                disabled={isLoading}
              >
                {isLoading ? "Przetwarzanie..." : "Wstaw post"}
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton 
                variant="outlined" 
                color="info" 
                fullWidth 
                onClick={pushBulkPosts}
                disabled={isLoading}
              >
                {isLoading ? "Przetwarzanie..." : "Wstaw wszystkie posty"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
